import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

export default function ImgWithGradient({ style, fluid }) {
  return <ApplyGradient style={style} fluid={fluid} />
}

const ApplyGradient = styled(Img)`
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`
