import styled from "styled-components"
import { styles } from "../globals"

const Section = styled.section`
  margin: 1rem auto;
  max-width: 90vw;
  padding-top: 3rem;
  @media (min-width: ${styles.smallScreenBreakpoint}) {
    padding-top {
      padding-top: 4rem;
    }
  }
`

export default Section
