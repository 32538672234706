import React from "react"
import styled from "styled-components"
import { styles } from "../../globals"

export default function Title({ title }) {
  return (
    <TitleWrapper>
      <h1>{title}</h1>
      <span className="underline" />
    </TitleWrapper>
  )
}

Title.defaultProps = {
  title: "A title",
}

const TitleWrapper = styled.div`
  font-size: 1.5rem;
  ${styles.letterSpacing({})};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
  .underline {
    display: block;
    width: 4rem;
    height: 0.1rem;
    background: black;
    margin: 0.4rem auto;
  }
`
