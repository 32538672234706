import React from "react"
import { HeaderWrapper } from "./DefaultHeader"
import ImgWithGradient from "./ImgWithGradient"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const GET_HOME_HEADER = graphql`
  {
    headerIndexBackground: file(relativePath: { eq: "header-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function HomeHeader({ img, children }) {
  return (
    <IndexHeader>
      <StaticQuery
        query={GET_HOME_HEADER}
        render={data => {
          return (
            <ImgWithGradient
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              fluid={data.headerIndexBackground.childImageSharp.fluid}
            />
          )
        }}
      />
      <div className="banner">{children}</div>
    </IndexHeader>
  )
}

const IndexHeader = styled(HeaderWrapper)`
  min-height: calc(100vh - 118px);
`
