import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ImgWithGradient from "./ImgWithGradient"
import styled from "styled-components"

const GET_DEFAULT_HEADER = graphql`
  {
    headerDefaultBackground: file(
      relativePath: { eq: "header-image-default.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function DefaultHeader({ children }) {
  return (
    <HeaderWrapper>
      <StaticQuery
        query={GET_DEFAULT_HEADER}
        render={data => {
          return (
            <ImgWithGradient
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              fluid={data.headerDefaultBackground.childImageSharp.fluid}
            />
          )
        }}
      />
      <div className="banner">{children}</div>
    </HeaderWrapper>
  )
}

export const HeaderWrapper = styled.header`
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .banner {
    position: relative;
    z-index: 2;
  }
`
